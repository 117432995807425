import request from '@/utils/request'


export function modelTrainlist(data) {
    return request({
        url: '/modelTrain/list',
        method: 'post',
        data
    })
}
export function modelTrainDel(data) {
    return request({
        url: '/modelTrain/delete',
        method: 'delete',
        data
    })
}