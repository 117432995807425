<template>
    <page-template>
        <div class="myModelCls">
            <div class="myModelItemCls" v-for="(item,index) in list" :key="index">
                <div class="VersionCls">模型名称：{{item.modelname}}</div>
                <div>训练时间：{{item.createTime}}</div>
                <div>模型类型：{{item.modelType | filterKeyValFun($constVal.modelTypeList)}}</div>
                <div>训练中， 进度
                    <span v-if="item.progress" class="txtCls">{{(item.progress*100).toFixed(2)}}%</span>
                    <span v-else class="txtCls">0%</span>
                    ；预计完成时间：
                    <span v-if="item.modelTrainPredictEndTime" class="txtCls">{{item.modelTrainPredictEndTime}}</span>
                    <span v-else>暂无</span>
                </div>
                <div class="delCls" @click="delFun(item)"><img class="delPngCls" src="../../assets/img/delete.png"/></div>
            </div>
        </div>
    </page-template>
</template>

<script>
    import { modelTrainlist, modelTrainDel } from '@/api/modelTrain/myModel'
    import { mapState } from 'vuex'
    export default {
        data() {
            return {
                list: []
            }
        },
        watch:{
            TRAIN_STATE(val) {
                this.list.forEach(row =>{
                    if(row.workID === val.workID) {
                        this.$vue.set(row,'progress', val.progress.toFixed(4))
                    }
                })
            }
        },
        computed: {
            ...mapState({
                TRAIN_STATE: state => state.modelTrain.TRAIN_STATE
            })
        },
        created() {
            this.modelTrainlistFun()
        },
        methods: {
            modelTrainlistFun(){
                this.list = []
                modelTrainlist().then(rsp =>{
                    if(rsp.status === 200) {
                        this.list = rsp.data
                    }
                })
            },
            delFun(item){
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'calBtnClass',
                    type: 'warning'
                }).then(() => {
                    const param = {
                        ids:[item.workID]
                    }
                    modelTrainDel(param).then(rsp =>{
                        if(rsp.status === 200) {
                            this.modelTrainlistFun()
                            this.$com.successNotify(rsp.message)
                        } else {
                            this.$com.warningNotify(rsp.message)
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .myModelCls {
        width: 100%;
        .myModelItemCls {
            text-align: left;
            width: 48%;
            margin: 10px 1% 10px 0px;
            padding: 5px 0.5% 5px 0.5%;
            background: #fff;
            float: left;
            position: relative;
            div{
                margin: 4px 0px;
            }
            .txtCls {
                font-size: 18px;
                font-weight: 600;
                color: #4d82f6;
            }
            .VersionCls {
                background-image: linear-gradient(90deg,#b3c8fa,#fff);
                font-size: 18px;
                padding: 5px;
                color: #111;
            }
            .delCls {
                height: 80%;
                width: 10%;
                min-width: 30px;
                right: 0;
                top: 20%;
                position: absolute;
                background: none;
                cursor: pointer;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                /*display: flex;*/
                display: none;
            }
        }
        .myModelItemCls:hover {
            .delCls {
                display: flex;
            }
        }
    }
</style>